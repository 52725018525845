import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Resume = () => (
  <Layout>
    <Seo title="About" />
    <h1 style={{
      fontSize: `1.75rem`,
    }}>About</h1>
    <p>
      I am a full-stack software developer with an emphasis on the front-end living in the Pacific Northwest. I specialize in leading, and
      working on, remote development teams.
    </p>

    <p>
      While creating new projects using new(ish) tech can be really fun, my favorite programming challenges often have
      to do with refactoring legacy code. Unraveling the intent of the original programmer can be difficult, but it is
      always rewarding. I enjoy being able to leave code better than I found it and old code often
      gives you that opportunity.
    </p>

    <p>
      While earning a PhD in American Studies, I spent a lot of time researching, writing, revising, giving feedback,
      and leading discussions. Leading a remote software engineering team draws on these skills every day, especially
      in the code review process, and I am often pleasantly surprised how relevant my experiences in the humanities
      continues to be.
    </p>
  </Layout>
)

export default Resume
